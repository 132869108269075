import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import '../../shared/plugins/composition.js'
import router from './router/index.js'
import store from './state/store.js'
import vuetify from './plugins/vuetify.js'
import App from './app.vue'
import '../../shared/validation/index.js'
import { loadRuntimeSettings } from '../../shared/utils/runtime-settings.js'
import { setHttpClientConfig } from '../../shared/utils/http-client.js'
import { SSO_TOKEN_API } from '../../shared/utils/api/login.js'
import { LOGIN, SSO_ERROR } from './router/route-names.js'

Vue.use(VueDOMPurifyHTML)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.devtools = process.env.NODE_ENV === 'development'

loadRuntimeSettings().then(() => {
	new Vue({
		vuetify,
		router,
		store,
		render: h => h(App)
	}
	).$mount('#app')
	store.dispatch('auth/getBearerTokenFromCookie').then(() => {
		setHttpClientConfig({
			unauthorizedHandler: async unauthorizedApiUrl => {
				try {
					const response = await fetch(unauthorizedApiUrl)
					if (!response.ok) {
						if (response.status === 401) {
							const data = await response.json()
							if (data.error === 'Incorrect Email Address or Password') {
								store.dispatch('auth/logOut', unauthorizedApiUrl.includes(SSO_TOKEN_API) ? SSO_ERROR : LOGIN)
							} else {
								store.dispatch('auth/sessionExpired', unauthorizedApiUrl.includes(SSO_TOKEN_API) ? SSO_ERROR : LOGIN)
							}
						}
					}
				} catch (error) {
					store.dispatch('auth/sessionExpired', unauthorizedApiUrl.includes(SSO_TOKEN_API) ? SSO_ERROR : LOGIN)
				}
			},
			loginUrl: '/api/authorize/csportaluser'
		})
	})
})
