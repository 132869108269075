import { configApi, setBearerToken } from '../../../../shared/utils/http-client.js'
import { loginApi, ssoLoginApi, refreshTokenApi, currentUserDetailsApi } from '../../../../shared/utils/api/login.js'
import router from '../../router/index.js'
import { changeUserBrandIds } from '../../../../shared/state/brands.js'
import { parseToken } from '../../../../shared/utils/parse-token.js'
import { customLogoutUrl } from '../../../../shared/state/configuration.js'
import { SESSION_EXPIRED } from '../../router/route-names.js'
const localStorageUserKey = 'auth.currentUser'

const getCookieValue = name =>
	document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

function saveState (key, state) {
	window.localStorage.setItem(key, JSON.stringify(state))
}

function setTokenCookie (token) {
	if (!token) {
		document.cookie = 'cs-portal-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
	} else {
		const parsedToken = parseToken(token)
		const expiry = new Date(0)
		expiry.setUTCSeconds(parsedToken.exp)
		document.cookie =
		'cs-portal-token=' + token + '; expires=' + expiry.toUTCString() + '; path=/; secure;'
	}
}

export const state = {
	currentUser: JSON.parse(window.localStorage.getItem(localStorageUserKey)),
	userDetails: {},
	sessionWarning: false,
	countdown: 0
}

let sessionCheckInterval

export const mutations = {
	SET_CURRENT_USER (state, user) {
		state.currentUser = user
		if (user === null) {
			window.localStorage.removeItem(localStorageUserKey)
			state.userDetails = {}
		} else {
			window.localStorage.setItem(localStorageUserKey, JSON.stringify(user))
		}
	},
	SET_CURRENT_TOKEN (state, tokenViewModel) {
		if (state.currentUser) {
			setBearerToken(tokenViewModel.token)
			setTokenCookie(tokenViewModel.token)
			state.currentUser.tokenExpiry = tokenViewModel.tokenExpiry
			saveState('auth.currentUser', state.currentUser)
		}
	},
	SET_USER_DETAILS (state, userDetails) {
		state.userDetails = userDetails
		changeUserBrandIds(userDetails.brandIds)
	},
	SET_SESSION_WARNING (state, warning) {
		state.sessionWarning = warning
	},
	SET_COUNTDOWN (state, countdown) {
		state.countdown = countdown
	}
}

export const getters = {
	loggedIn: state => state.currentUser?.userId != null,
	userPermissionsRetrieved (state) {
		return !!state.userDetails?.productPermissions
	},
	tokenExpiry: state => state.currentUser?.tokenExpiry,
	username (state) {
		return state.userDetails?.username
	},
	userFullName (state) {
		return state.userDetails?.fullName
	},
	userId (state) {
		return state.currentUser?.userId
	},
	productPermissions (state) {
		return state.userDetails?.productPermissions || []
	},
	hasPermission: (_, getters) => permissionId => {
		if (!getters.userId) return false
		return getters.productPermissions.includes(permissionId)
	},
	loggedInWithSSO (state) {
		return state.currentUser?.isSSO
	},
	userHasUniversalBrand (state) {
		return state.userDetails?.brandIds.includes(0)
	},
	sessionWarning (state) {
		return state.sessionWarning
	},
	countdown (state) {
		return state.countdown
	}
}

export const actions = {
	ssoLogIn ({ dispatch }, { identityClaimTypeName, stateGuid, productId }) {
		return ssoLoginApi({ identityClaimTypeName, stateGuid, productId }).then(({ data }) => dispatch('handleLogin', { data: data, isSSO: true }))
	},
	logIn ({ commit, dispatch }, credentials) {
		return loginApi(credentials)
			.then(response => {
				if (response.status === 202) {
					localStorage.setItem('csMfaGuid', response.data.mfaGuid)
					return {
						otpLoginRequired: true
					}
				} else { dispatch('handleLogin', { data: response.data, isSSO: false }) }
			}
			)
			.catch(({ response }) => {
				if (response && response.status === 401) {
					commit('SET_CURRENT_USER', null)
					throw new Error(response.data.join())
				}
				if (response?.status === 403) {
					throw new Error(response.status)
				}
				throw new Error('There was a problem logging you in.')
			})
	},
	otpLogIn ({ commit, dispatch }, { otpCode, mfaGuid } = {}) {
		return configApi
			.post('/api/Authorize/CSPortalLoginOtp', { loginCode: otpCode, mfaGuid })
			.then(response => {
				dispatch('handleLogin', { data: response.data, isSSO: false })
				return response.data
			})
	},
	refreshToken ({ commit, state, dispatch }) {
		if (!state.currentUser) return Promise.resolve(null)
		return refreshTokenApi()
			.then(response => {
				const tokenViewModel = {
					token: response.data.token,
					tokenExpiry: parseToken(response.data.token).exp
				}
				commit('SET_CURRENT_TOKEN', tokenViewModel)
				dispatch('startSessionCheck')
				return tokenViewModel
			})
			.catch(error => {
				if (error.response && error.response.status === 401) {
					dispatch('logOut', SESSION_EXPIRED)
				} else {
					console.warn(error)
				}
				return null
			})
	},
	async handleLogin ({ commit, dispatch }, { data, isSSO }) {
		setBearerToken(data.token)
		setTokenCookie(data.token)
		const parsedToken = parseToken(data.token)
		const user = {
			tokenExpiry: parsedToken.exp,
			userId: parsedToken.userId,
			isSSO: isSSO
		}
		commit('SET_CURRENT_USER', user)
		dispatch('startSessionCheck')
		if (isSSO) {
			await dispatch('getUserDetails')
		}
	},
	getBearerTokenFromCookie ({ commit, dispatch }) {
		const token = getCookieValue('cs-portal-token')
		if (token) {
			setBearerToken(token)
			const parsedToken = parseToken(token)
			const user = {
				userId: parsedToken.userId,
				tokenExpiry: parsedToken.exp
			}
			commit('SET_CURRENT_USER', user)
			dispatch('startSessionCheck')
		} else {
			commit('SET_CURRENT_USER', null)
		}
	},
	// Get sensitive user details.
	getUserDetails ({ commit, getters } = {}) {
		if (getters.loggedIn) {
			return currentUserDetailsApi()
				.then(({ data }) => {
					const userDetails = {
						username: data.currentUserDetails.userPreview.username,
						fullName: data.currentUserDetails.userPreview.fullName,
						brandIds: data.currentUserDetails.brandIds,
						productPermissions: data.currentUserDetails.permissionIds
					}
					commit('SET_USER_DETAILS', userDetails)
					changeUserBrandIds(userDetails.brandIds)
					return userDetails
				})
		}
	},
	async logOut ({ commit, getters }, routeName) {
		clearInterval(sessionCheckInterval)
		commit('SET_CURRENT_USER', null)
		setBearerToken(null)
		if (routeName === 'SESSION_EXPIRED') {
			return
		}
		if (routeName == null) {
			routeName = 'login'
		}
		document.cookie = 'cs-portal-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
		const tenantGuid = sessionStorage.getItem('tenantGuid')
		const authHandler = sessionStorage.getItem('authHandler')
		if (getters.loggedInWithSSO && tenantGuid != null && authHandler != null) {
			sessionStorage.removeItem('tenantGuid')
			sessionStorage.removeItem('authHandler')
			window.location.href = `${authHandler}/signOut/${tenantGuid}`
		} else if (customLogoutUrl.value) {
			let logoutUrl = customLogoutUrl.value
			if (!logoutUrl.startsWith('http')) {
				logoutUrl = `https://${logoutUrl}`
			}
			window.location.href = logoutUrl
		} else {
			await router.push({ name: routeName })
			setTimeout(() => location.reload(), 250)
		}
	},
	startSessionCheck ({ state, commit, dispatch }) {
		clearInterval(sessionCheckInterval)
		sessionCheckInterval = setInterval(() => {
			const expiry = new Date(0)
			expiry.setUTCSeconds(state.currentUser.tokenExpiry)
			const now = new Date()
			const timeLeft = expiry - now

			if (timeLeft <= 0) {
				commit('SET_CURRENT_USER', null)
				commit('SET_SESSION_WARNING', false) // Ensure modal is hidden
				dispatch('sessionExpired')
			} else if (timeLeft <= 60000) { // Show 1 minute before session expires
				commit('SET_SESSION_WARNING', true)
				commit('SET_COUNTDOWN', Math.floor(timeLeft / 1000)) // Set initial countdown value

				// Start countdown
				const countdownInterval = setInterval(() => {
					const newTimeLeft = expiry - new Date()
					if (newTimeLeft <= 0 || !state.sessionWarning) {
						clearInterval(countdownInterval)
						if (newTimeLeft <= 0) {
							dispatch('sessionExpired')
						}
					} else {
						commit('SET_COUNTDOWN', Math.floor(newTimeLeft / 1000))
					}
				}, 1000)
			} else {
				commit('SET_SESSION_WARNING', false)
			}
		}, 60000) // Check every minute
	},
	sessionExpired ({ commit, dispatch }) {
		commit('SET_CURRENT_USER', null)
		commit('SET_SESSION_WARNING', false)
		dispatch('logOut', SESSION_EXPIRED)
		router.push({ name: 'SESSION_EXPIRED' })
	}
}
