// src/plugins/vuetify.js
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

const opts = {
	theme: {
		themes: {
			light: {
				primary: '#0178aa',
				success: '#39a275',
				background: '#f8f9fd',
				'nav-bar-background': '#3a3950'
			}
		}
	},
	icons: {
		iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	}
}

export default new Vuetify(opts)
