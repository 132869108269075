import store from '../state/store.js'
import {
	FORGOTTEN_PASSWORD,
	SESSION_EXPIRED,
	DATA_SUBJECT_SEARCH,
	RESET_PASSWORD,
	CREATE_DATA_SUBJECT,
	DATA_SUBJECT,
	LOGIN,
	FORBIDDEN,
	UNAUTHORISED,
	NOT_FOUND,
	SSO_ERROR,
	LOGOUT,
	DIRECT_LOGIN_NOT_ALLOWED,
	ACCOUNT_LOCKED_OUT
} from './route-names.js'
import {
	USER_MANAGEMENT,
	USER_MANAGEMENT_CREATE_USER,
	USER_MANAGEMENT_EDIT_USER,
	CS_PORTAL_PRODUCT_ROLES,
	CS_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
	CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE
}
from '../../../shared/router/route-names.js'
import {
	SEE_DATA_SUBJECT_MANAGEMENT_AREA,
	USE_CREATE_DATA_SUBJECTS_AREA,
	CREATE_UPDATE_CSPORTAL_USERS,
	SEE_MANAGE_CSPORTAL_USERS_AREA
} from '../../../shared/permissions/cs-portal-permissions.js'
import { canCreateDataSubjects, canCreateCsPortalUsers, waitForLoad } from '../../../shared/state/configuration.js'

export default [
	{ path: '/', redirect: { name: DATA_SUBJECT_SEARCH } },
	{
		path: '/create-data-subject',
		name: CREATE_DATA_SUBJECT,
		component: () => import('../components/views/data-subjects/_data-subject-create.vue'),
		meta: {
			authRequired: true,
			permissionRequired: USE_CREATE_DATA_SUBJECTS_AREA
		},
		async beforeEnter (to, from, next) {
			if (typeof canCreateDataSubjects.value === 'undefined') {
				await waitForLoad()
			}

			if (canCreateDataSubjects.value === false) {
				next({ path: '/401' })
			} else if (canCreateDataSubjects.value === true) {
				next()
			}
		}
	},
	{
		path: '/data-subject-search',
		name: DATA_SUBJECT_SEARCH,
		component: () => import('../components/views/data-subjects/_data-subject-search.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_DATA_SUBJECT_MANAGEMENT_AREA
		}
	},
	{
		path: '/data-subject',
		name: DATA_SUBJECT,
		component: () => import('../components/views/data-subjects/data-subject/_data-subject.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_DATA_SUBJECT_MANAGEMENT_AREA
		},
		props: route => ({
			id: parseInt(route.query.id),
			brandId: parseInt(route.query.brandId),
			allIds: route.query.allIds ? route.query.allIds.split(',').map(id => Number(id)) : []
		})
	},
	{
		path: '/forgotten-password',
		name: FORGOTTEN_PASSWORD,
		component: () => import('../components/views/login/_forgotten-password.vue')
	},
	{
		path: '/session-expired',
		name: SESSION_EXPIRED,
		component: () => import('../components/views/session-expired.vue')
	},
	{
		path: '/reset-password',
		name: RESET_PASSWORD,
		component: () => import('../components/views/login/_reset-password.vue'),
		props: route => ({
			hash: route.query.hash
		})
	},
	{
		path: '/product-roles/create-role',
		name: CS_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
		component: () => import('../../../shared/components/product-roles/_product-roles-create-role.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CREATE_UPDATE_CSPORTAL_USERS
		},
		props: route => ({
			productIdToView: Number(route.query.productId),
			loggedInProductId: Number(route.query.loggedInProductId)
		})
	},
	{
		path: '/product-roles/edit-role',
		name: CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE,
		component: () => import('../../../shared/components/product-roles/_product-roles-edit-role.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_MANAGE_CSPORTAL_USERS_AREA
		},
		props: route => ({
			roleId: Number(route.query.roleId),
			productIdToView: Number(route.query.productIdToView),
			loggedInProductId: Number(route.query.loggedInProductId)
		})
	},
	{
		path: '/product-roles',
		name: CS_PORTAL_PRODUCT_ROLES,
		component: () => import('../../../shared/components/product-roles/_product-roles.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_MANAGE_CSPORTAL_USERS_AREA
		},
		props: {
			productIdToView: Number(2),
			loggedInProductId: Number(2)
		}
	},
	{
		path: '/user-management/create-user',
		name: USER_MANAGEMENT_CREATE_USER,
		component: () => import('../../../shared/components/user-management/_user-management-create-user.vue'),
		meta: {
			authRequired: true,
			permissionRequired: CREATE_UPDATE_CSPORTAL_USERS
		},
		async beforeEnter (to, from, next) {
			if (typeof canCreateCsPortalUsers.value === 'undefined') {
				await waitForLoad()
			}

			if (canCreateCsPortalUsers.value === false) {
				next({ path: '/401' })
			} else if (canCreateCsPortalUsers.value === true) {
				next()
			}
		}
	},
	{
		path: '/user-management/edit-user',
		name: USER_MANAGEMENT_EDIT_USER,
		component: () => import('../../../shared/components/user-management/_user-management-edit-user.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_MANAGE_CSPORTAL_USERS_AREA
		},
		props: route => ({
			userId: Number(route.query.userId),
			ssoEnabled: Boolean(route.query.ssoEnabled),
			ssoBypassDomains: route.query.ssoBypassDomains
		})
	},
	{
		path: '/user-management',
		name: USER_MANAGEMENT,
		component: () => import('../../../shared/components/user-management/_user-management.vue'),
		meta: {
			authRequired: true,
			permissionRequired: SEE_MANAGE_CSPORTAL_USERS_AREA
		}
	},
	{
		path: '/login',
		name: LOGIN,
		component: () => import('../components/views/login/_login.vue'),
		meta: {
			beforeResolve (routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters['auth/loggedIn']) {
					// Redirect to the home page instead
					next({ name: DATA_SUBJECT_SEARCH })
				} else {
					// Continue to the login page
					next()
				}
			}
		}
	},
	{
		path: '/otp-login',
		name: 'otp-login',
		component: () => import('../components/views/login/_otp-login.vue'),
		props: route => ({
			otp: route.query.otp
		})
	},
	{
		path: '/404',
		name: NOT_FOUND,
		component: () => import('../components/views/_404.vue'),
		// Allows props to be passed to the 404 page through route
		// params, such as `resource` to define what wasn't found.
		props: true
	},
	{
		path: '/403',
		name: FORBIDDEN,
		component: () => import('../components/views/_403.vue'),
		// Allows props to be passed to the 403 page through route
		// params, such as `resource` to define what wasn't found.
		props: true
	},
	{
		path: '/401',
		name: UNAUTHORISED,
		component: () => import('../components/views/_401.vue'),
		// Allows props to be passed to the 403 page through route
		// params, such as `resource` to define what wasn't found.
		props: true
	},
	{
		path: '/sso/error',
		name: SSO_ERROR,
		component: () => import('../../../cs-portal/src/components/views/_sso-error.vue'),
		// Allows props to be passed to the 403 page through route
		// params, such as `resource` to define what wasn't found.
		props: true
	},
	{
		path: '/sso/logout',
		name: LOGOUT,
		component: () => import('../../../cs-portal/src/components/views/_sso-logout.vue'),
		// Displays message to user that logout has occurred
		props: true
	},
	// Redirect any unmatched routes to the 404 page. This may
	// require some server configuration to work in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	{
		path: '*',
		redirect: '404'
	},
	// SSO Direct Login
	{
		path: '/direct-login-not-allowed',
		name: DIRECT_LOGIN_NOT_ALLOWED,
		component: () => import('../../../shared/components/direct-login-not-allowed.vue'),
		props: true
	},
	{
		path: '/account-locked-out',
		name: ACCOUNT_LOCKED_OUT,
		component: () => import('../components/views/login/_account-locked-out.vue')
	}
]
