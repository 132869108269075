<template>
	<div id="app">
		<link
			rel="preconnect"
			href="https://fonts.gstatic.com"
		>
		<v-app>
			<ApplicationLayout
				v-if="showMainLayout && !sessionExpired"
				:links="links"
				:show-spinner="loadingSpinner"
			>
				<RouterView :key="$route.fullPath" />
			</ApplicationLayout>
			<RouterView
				v-else
				:key="$route.fullPath"
			/>
			<v-snackbar
				:value="!!snackbar"
				top
				:color="snackbar ? snackbar.color : ''"
				@input="snackbar = null"
			>
				{{ snackbar ? snackbar.text : '' }}
				<template #action="{ attrs }">
					<v-btn
						color="white"
						text
						v-bind="attrs"
						@click="snackbar = null"
					>
						Close
					</v-btn>
				</template>
			</v-snackbar>
			<UnsavedChangesModal />
			<SessionExpiryModal
				v-if="sessionWarning && !sessionExpired"
				title="Session Expiry Warning"
				:countdown="countdown"
				:keep-logged-in="renewSession"
				:log-out="logOut"
			/>
		</v-app>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import appConfig from './app.config.json'
import ApplicationLayout from '../../shared/layouts/application-layout.vue'
import {
	CREATE_DATA_SUBJECT,
	DATA_SUBJECT_SEARCH
} from './router/route-names.js'
import { USER_MANAGEMENT, CS_PORTAL_PRODUCT_ROLES } from '../../shared/router/route-names.js'
import { SEE_DATA_SUBJECT_MANAGEMENT_AREA, USE_CREATE_DATA_SUBJECTS_AREA, SEE_MANAGE_CSPORTAL_USERS_AREA } from '../../shared/permissions/cs-portal-permissions.js'
import { canCreateCsPortalUsers, canCreateDataSubjects } from '../../shared/state/configuration.js'
import { loadingSpinner } from '../../shared/state/loading-spinner.js'
import { snackbar } from '../../shared/state/snackbar.js'
import UnsavedChangesModal from '../../shared/components/unsaved-changes-modal.vue'
import SessionExpiryModal from '../../shared/components/session-expiry-modal.vue'

export default {
	components: { UnsavedChangesModal, ApplicationLayout, SessionExpiryModal },
	provide () {
		return {
			logOutAndRedirect: this.logOut,
			userFullName: () => this.userFullName
		}
	},
	page: {
		// All subcomponent titles will be injected into this template.
		titleTemplate (title) {
			title = typeof title === 'function' ? title(this.$store) : title
			return title ? `${title} | ${appConfig.title}` : appConfig.title
		}
	},
	setup () {
		return {
			loadingSpinner,
			snackbar
		}
	},
	data () {
		return {
			sessionExpired: false
		}
	},
	computed: {
		...mapGetters('auth', ['loggedIn', 'userFullName', 'hasPermission', 'sessionWarning', 'countdown']),
		showMainLayout () {
			return this.$route.meta?.authRequired
		},
		links () {
			let links = [
				{
					text: 'Search',
					icon: 'mdi-magnify',
					route: { name: DATA_SUBJECT_SEARCH },
					permission: SEE_DATA_SUBJECT_MANAGEMENT_AREA,
					sublinks: [],
					enabled: true
				},
				{
					text: 'Create data subject',
					icon: 'mdi-account-plus',
					route: { name: CREATE_DATA_SUBJECT },
					permission: USE_CREATE_DATA_SUBJECTS_AREA,
					enabled: canCreateDataSubjects.value,
					sublinks: []
				},
				{
					text: 'Administration',
					icon: 'mdi-account-circle',
					permission: SEE_MANAGE_CSPORTAL_USERS_AREA,
					enabled: true,
					sublinks: [
						{
							text: 'CS Portal Users',
							route: { name: USER_MANAGEMENT },
							enabled: canCreateCsPortalUsers.value
						},
						{
							text: 'User Roles',
							route: { name: CS_PORTAL_PRODUCT_ROLES },
							enabled: true
						}
					]
				}
			]
			links = links.filter(link => link.enabled).filter(({ permission }) => this.hasPermission(permission) || !permission)
			links.forEach(link => {
				link.sublinks = link.sublinks.filter(sublink => sublink.enabled)
			})
			return links
		}
	},
	mounted () {
		const link = document.createElement('link')
		link.type = 'image/svg+xml'
		link.rel = 'shortcut icon'
		link.href = require('../src/assets/favicon.svg')
		document.getElementsByTagName('head')[0].appendChild(link)
	},
	methods: {
		...mapActions('auth', ['logOut', 'refreshToken']),
		renewSession () {
			this.refreshToken().then(() => {
				this.$store.commit('auth/SET_SESSION_WARNING', false)
			})
		}
	}
}
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
	// Allow element/type selectors, because this is global CSS.
	// stylelint-disable selector-max-type, selector-class-pattern
	@import './styles';

	// poppins font
	@font-face {
		font-family: 'Poppins';
		src: url('./fonts/Poppins-Black.ttf') format('truetype');
		font-weight: 900;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('./fonts/Poppins-Bold.ttf') format('truetype');
		font-weight: 700;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('./fonts/Poppins-Medium.ttf') format('truetype');
		font-weight: 500;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('./fonts/Poppins-Regular.ttf') format('truetype');
		font-weight: 400;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('./fonts/Poppins-Light.ttf') format('truetype');
		font-weight: 300;
	}

	@font-face {
		font-family: 'Poppins';
		src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
		font-weight: 100;
	}

	@font-face {
		font-family: 'Poppins-Italic';
		src: url('./fonts/Poppins-Italic.ttf') format('truetype');
		font-weight: 400;
	}

	// Normalize default styles across browsers,
	// https://necolas.github.io/normalize.css/
	@import '~normalize.css/normalize.css';
	// Style loading bar between pages.
	// https://github.com/rstacruz/nprogress
	@import '~nprogress/nprogress.css';

	// Design variables and utilities from src/design.

	html,
	body,
	.v-application {
		height: 100%;
		overflow: hidden !important;
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
		font-family: 'Poppins', sans-serif;
	}

	body {
		::-webkit-scrollbar {
			width: 15px;
			height: 15px;
		}

		::-webkit-scrollbar-track {
			background: none;
		}

		::-webkit-scrollbar-thumb {
			background: #cfd8dc;
			background-clip: padding-box;
			border: 4px solid rgba(0, 0, 0, 0);
			border-radius: 99999px;
		}
	}

	.v-select-list {
		margin-top: 10px !important;
		background-color: orange;

		::-webkit-scrollbar-track {
			background: #fff !important;
		}
	}

	#app {
		height: 100%;
		overflow: hidden;
		@extend %typography-small;
	}

	// ===
	// Base element styles
	// ===

	a,
	a:visited {
		color: #39a275;
	}

	h1 {
		@extend %typography-xxlarge;
	}

	h2 {
		@extend %typography-xlarge;
	}

	h3 {
		@extend %typography-large;
	}

	h4 {
		@extend %typography-medium;
	}

	h5,
	h6 {
		@extend %typography-small;
	}

	// override vuetify default style globally

	.v-application .text-h1.text-h1,
	.v-application .text-h2.text-h2,
	.v-application .text-h3.text-h3,
	.v-application .text-h4.text-h4,
	.v-application .text-h5.text-h5,
	.v-application .text-h6.text-h6,
	.v-application .text-subtitle-1.text-subtitle-1,
	.v-application .text-subtitle-2.text-subtitle-2,
	.v-application .text-body-1.text-body-1,
	.v-application .text-body-2.text-body-2,
	.v-application .text-button.text-button,
	.v-application .text-caption.text-caption,
	.v-application .text-overline.text-overline {
		font-family: 'Poppins', sans-serif !important;
	}

	.v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
	.v-slide-group__next,
	.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
	.v-slide-group__prev {
		display: none !important;
	}

	.v-tooltip__content {
		width: 300px;
	}

	.v-radio {
		.v-label {
			font-size: 0.875rem !important;
		}
	}

	.v-input--checkbox {
		.v-label {
			font-size: 0.875rem !important;
		}
	}

	// Global Card Styles
	.cassie_card {
		border-left: 5px left grey;
	}

	// Tab background none for consent banner page
	.theme--light.v-tabs-items {
		width: 100%;
		background-color: rgba(0, 0, 0, 0) !important;
	}

	// ===
	// Vendor
	// ===

	#nprogress .bar {
		background: #1976d2;
	}
</style>
