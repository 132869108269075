import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
import store from '../state/store.js'
import { LOGIN, SSO_ERROR } from './route-names.js'
import routes from './routes.js'
import { getLandingPageInfo } from '../../../shared/utils/api/landing-page.js'
import { getEnv } from '../../../shared/utils/runtime-settings.js'
import { uuid } from '../../../shared/utils/uuid.js'

// Silencing push/replace errors (NavigationDuplicated)
// Required after upgrading to v3.1
// https://github.com/vuejs/vue-router/issues/2881
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
Vue.use(VueMeta, {
	// The component option name that vue-meta looks for meta info on.
	keyName: 'page'
})

const router = new VueRouter({
	routes,
	// Use the HTML5 history API (i.e. normal-looking routes)
	// instead of routes with hashes (e.g. example.com/#/about).
	// This may require some server configuration in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	mode: 'history',
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	base: process.env.BASE_URL
})
const getTimeBetweenDates = (startDate, endDate) => {
	const start = new Date(startDate)
	const end = new Date(endDate)
	const diff = end.getTime() - start.getTime()
	return diff
}

const refreshToken = () => {
	return new Promise((resolve, reject) => {
		const tokenExpiry = store.getters['auth/tokenExpiry']
		if (!tokenExpiry) {
			resolve()
			return
		}
		const timeBetweenDates = getTimeBetweenDates(new Date(), tokenExpiry)
		const nextCheck = timeBetweenDates - (1000 * 60 * 5)
		if (nextCheck <= 0) {
			store.dispatch('auth/refreshToken').then(() => resolve()).catch(() => { reject(new Error('Token already expired')) })
		} else { resolve() }
	})
}
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
	// If this isn't an initial page load...
	if (routeFrom.name !== null) {
		// Start the route progress bar.
		NProgress.start()
	}
	// Check if user is logged in but we haven't got their permission details
	if (store.getters['auth/loggedIn'] && !store.getters['auth/userPermissionsRetrieved']) {
		// Make sure bearer token is set before calling endpoints
		await store.dispatch('auth/getBearerTokenFromCookie')
		if (store.getters['auth/loggedIn']) {
			await store.dispatch('auth/getUserDetails').catch(error => {
				console.warn(error)
				next({ path: '/login' })
			})
		} else {
			next({ path: '/login' })
		}
	}
	const authRequired = routeTo.meta?.authRequired
	const permission = routeTo.meta?.permissionRequired
	const refreshTokenRequired = store.getters['auth/loggedIn']
	if (authRequired && refreshTokenRequired) {
		await refreshToken().catch(() => {
			return next({ name: LOGIN })
		})
	}
	const userLoggedIn = store.getters['auth/loggedIn']
	const isLogin = routeTo.name === LOGIN
	if (userLoggedIn) {
		if (permission !== undefined && !store.getters['auth/hasPermission'](permission)) {
			next({ path: '/403' })
		} else if (isLogin) {
			next({ path: '/' })
		} else {
			next()
		}
	} else if (authRequired || isLogin) {
		const response = await getLandingPageInfo(2)
		const loginConfig = response.data?.loginConfig
		const urlParams = new URLSearchParams(window.location.search)
		if (response.status === 204 || loginConfig?.loginType !== 'SSO' || urlParams.has('noredirect')) {
			if (!isLogin) {
				next({ name: LOGIN })
			} else {
				next()
			}
		} else {
			const identityClaimTypeName = loginConfig.identityClaimTypeName
			const tenantGuid = loginConfig.tenantGuid
			sessionStorage.setItem('tenantGuid', tenantGuid)
			const authHandler = getEnv('VUE_APP_BASE_URL_AUTH')
			sessionStorage.setItem('authHandler', authHandler)
			const ssoState = sessionStorage.getItem('sso-state')
			if (ssoState) {
				const { identityClaimTypeName, stateGuid, productId } = JSON.parse(ssoState)
				sessionStorage.removeItem('sso-state')
				try {
					await store.dispatch('auth/ssoLogIn', { identityClaimTypeName, stateGuid, productId })
					next()
				} catch (_) {
					router.push({ name: SSO_ERROR })
				}
			} else {
				const stateGuid = uuid()
				sessionStorage.setItem('sso-state', JSON.stringify({ identityClaimTypeName, stateGuid, productId: 2 }))
				window.location.href = `${authHandler}/${tenantGuid}/${stateGuid}`
			}
		}
	} else {
		next()
	}
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
	// Complete the animation of the route progress bar.
	NProgress.done()
})
export default router
