export const NOT_FOUND = 'NOT_FOUND'
export const FORBIDDEN = 'FORBIDDEN'
export const UNAUTHORISED = 'UNAUTHORISED'
export const LOGIN = 'LOGIN'
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD'
export const SESSION_EXPIRED = 'SESSION_EXPIRED'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const DATA_SUBJECT_SEARCH = 'DATA_SUBJECT_SEARCH'
export const DATA_SUBJECT = 'DATA_SUBJECT'
export const CREATE_DATA_SUBJECT = 'CREATE_DATA_SUBJECT'
export const SSO_ERROR = 'SSO_ERROR'
export const LOGOUT = 'LOGOUT'
export const DIRECT_LOGIN_NOT_ALLOWED = 'direct-login-not-allowed'
export const ACCOUNT_LOCKED_OUT = 'account-locked-out'
